import './bootstrap';
import 'flowbite';
import Rellax from 'rellax';

import "@fontsource/montserrat/400.css"; // Reguläre Montserrat
import "@fontsource/montserrat/700.css"; // Bold Montserrat
import "@fontsource/raleway/100.css";    // Thin Raleway
import "@fontsource/raleway/400.css";    // Normal Raleway
import "@fontsource/raleway/700.css";    // Bold Raleway

// Wrapping Rellax in try-catch to prevent build issues
document.addEventListener('DOMContentLoaded', function () {
    try {
        const parallaxImage = document.querySelector('.rellax');
        
        // Nur fortfahren, wenn ein .rellax Element existiert
        if (!parallaxImage) return;

        // Überprüfen, ob Intersection Observer unterstützt wird
        if ('IntersectionObserver' in window) {
            let observer = new IntersectionObserver(function(entries, observer) {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        try {
                            // Parallax-Effekt starten, wenn das Bild sichtbar wird
                            var rellax = new Rellax('.rellax');
                            // Beobachtung beenden
                            observer.unobserve(parallaxImage);
                        } catch (e) {
                            console.error('Fehler beim Initialisieren von Rellax:', e);
                        }
                    }
                });
            }, {
                root: null, // Viewport als Root verwenden
                threshold: 0 // Sobald irgendein Teil des Bildes sichtbar ist
            });
            observer.observe(parallaxImage);
        } else {
            // Fallback für Browser, die Intersection Observer nicht unterstützen
            try {
                var rellax = new Rellax('.rellax');
            } catch (e) {
                console.error('Fehler beim Initialisieren von Rellax (Fallback):', e);
            }
        }
    } catch (e) {
        console.error('Fehler im Rellax Setup:', e);
    }
});
